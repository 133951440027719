import Vue from 'vue'
import Vuex from 'vuex'
import store from  './store/store'
import App from './App.vue'
import router from './router'
import scroll from 'vue-seamless-scroll'
import Vant from 'vant'
import 'vant/lib/index.css'
import '@/assets/iconfont/iconfont.css'
import bootstrap from '@/bootstrap'
import { Lazyload } from 'vant';
import 'amfe-flexible'

Vue.prototype.oss="https://ntyz.oss-cn-shanghai.aliyuncs.com/image/zdd/"

Vue.use(Lazyload);
Vue.use(Vuex)
Vue.use(scroll)
Vue.config.productionTip = false
bootstrap({router, store,message: Vue.prototype.$toast})

Vue.use(Vant)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
