//四省五入
export function keepToInt (value) {
  let data='';
  if(value == '' || value == null || value==0){
    data=0;
  }else{
    data=Math.round(value)
  }
  return data;
}
//保留两位小数
export function keepTwoNum (value) {
  // let data='';
  // if(value == '' || value == null || value==0){
  //   data=0;
  // }else{
  //   data=Number(Number(value).toFixed(2))
  // }
  // return data;
  return value
}

//数字中文转换
export function ChineseNumber (v){
  let data=['一','二','三','四','五','六','七','八','九','十']
  let d
  if(v=='' || v==undefined){
    d=''
  }else{
    d=data[v-1]
  }
  return d
}
export function getRandom(min, max,num) {
  let data=[]
  for(var i=0;i<num;i++){
    data.push(Math.floor(Math.random() * (max+1-min)) + min)
  }
  return data
}
export function formatDate(date,dateFormat){

  let v=''
  if(dateFormat == 'Y'){
    v = `${date.getFullYear()}`
  }else if(dateFormat == 'Y-M'){
    v = `${date.getMonth() + 1}-${date.getDate()}`
  }else if(dateFormat == 'Y-M-D'){
    v = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  }
  return v
}

export async function loadJs(src) {
  return new Promise((resolve,reject)=>{
    let script = document.createElement('script');
    script.type = "text/javascript";
    script.src= src;
    document.body.appendChild(script);

    script.onload = ()=>{
      resolve();
    }
    script.onerror = ()=>{
      reject();
    }
  })
}

//是否微信
export function isWeixin(){
  var ua = navigator.userAgent.toLowerCase()
  if(ua.match(/MicroMessenger/i)=="micromessenger") {
    return true
  } else {
    return false
  }
}
//是否手机端
export function isMobile(){
  var regex_match = /(nokia|iphone|android|motorola|^mot-|softbank|foma|docomo|kddi|up.browser|up.link|htc|dopod|blazer|netfront|helio|hosin|huawei|novarra|CoolPad|webos|techfaith|palmsource|blackberry|alcatel|amoi|ktouch|nexian|samsung|^sam-|s[cg]h|^lge|ericsson|philips|sagem|wellcom|bunjalloo|maui|symbian|smartphone|midp|wap|phone|windows ce|iemobile|^spice|^bird|^zte-|longcos|pantech|gionee|^sie-|portalmmm|jigs browser|hiptop|^benq|haier|^lct|operas*mobi|opera*mini|320x320|240x320|176x220)/i;
  var u = navigator.userAgent;
  if (null == u) {
    return true;
  }
  var result = regex_match.exec(u);

  if (null == result) {
    return false
  } else {
    return true
  }
}

//获取订单号
export function getordernum(num){
  let random = Math.floor((Math.random()+Math.floor(Math.random()*9+1))*Math.pow(10,num-1))
  return new Date().getTime().toString()+random.toString()
}
export function getQueryString(name){
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  var r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return unescape(r[2]);
  }
  return null;
}
export function delQueryString(url,paramKey){
  var urlParam = window.location.search.substr(1);  //页面参数
  var beforeUrl = url.substr(0, url.indexOf("?"));  //页面主地址（参数之前地址）
  var nextUrl = "";
  var arr = new Array();
  if (urlParam != "") {
    var urlParamArr = urlParam.split("&"); //将参数按照&符分成数组
    for (var i = 0; i < urlParamArr.length; i++) {
      var paramArr = urlParamArr[i].split("="); //将参数键，值拆开
      //如果键雨要删除的不一致，则加入到参数中
      if (paramArr[0] != paramKey) {
        arr.push(urlParamArr[i]);
      }
    }
  }
  if (arr.length > 0) {
    nextUrl = "?" + arr.join("&");
  }
  url = beforeUrl + nextUrl;
  return url;
}
