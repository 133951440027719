import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
/* 话费 */
const phonebillRouter = [
  {
    path: '/paycoupon',
    name: '我的优惠券',
    component: () => import('@/pages/phonebill/pay100_1'),
    meta: {
      file_name:'pay100_1'
    }
  },
  {
    path: '/paycoupon',
    name: '我的优惠券',
    component: () => import('@/pages/phonebill/pay100_1'),
    meta: {
      file_name:'pay100_1'
    }
  },
  {
    path: '/pay101',
    name: '话费月月领',
    component: () => import('@/pages/phonebill/pay101_1'),
    meta: {
      file_name:'pay101_1'
    }
  },
  {
    path: '/duihuan',
    name: '领取话费权益',
    component: () => import('@/pages/phonebill/duihuan'),
    meta: {
      file_name:'duihuan'
    }
  },
  {
    path: '/pay100',
    name: '话费月月领',
    component: () => import('@/pages/phonebill/pay100_2'),
    meta: {
      file_name:'pay100_1'
    }
  },
  {
    path: '/success',
    name: '支付成功',
    component: () => import('@/pages/phonebill/success'),
    meta: {
      file_name:'success'
    }
  },
  {
    path: '/refund',
    name: '申请退款',
    component: () => import('@/pages/phonebill/refund'),
    meta: {
      file_name:'refund'
    }
  },

  {
    path: '/sale/qzy',
    name: '锁定百元话费券',
    component: () => import('@/pages/phonebill/qzy'),
    meta: {
      file_name:'qzy'
    }
  },
  {
    path: '/sale/activity',
    name: '好多省',
    component: () => import('@/pages/phonebill/activity'),
    meta: {
      file_name:'activity'
    }
  },
  {
    path: '/sale/seckill',
    name: '享开盒子会员享',
    component: () => import('@/pages/phonebill/seckill'),
    meta: {
      file_name:'seckill'
    }
  },
  {
    path: '/sale/zzsf',
    name: '29.9元充值会员专享话费优惠券',
    component: () => import('@/pages/phonebill/zzsf'),
    meta: {
      file_name:'zzsf'
    }
  },
  {
    path: '/charge',
    name: '充值200元话费券',
    component: () => import('@/pages/phonebill/charge'),
    meta: {
      file_name:'charge'
    }
  },
  {
    path: '/payv12',
    name: '0元抢购大牌手机',
    component: () => import('@/pages/phonebill/payv12'),
    meta: {
      file_name:'payv12'
    }
  },
  {
    path: '/ldy',
    name: '恭喜您获得200元话费券',
    component: () => import('@/pages/phonebill/ldy'),
    meta: {
      file_name:'ldy'
    }
  },
  {
    path: '/pink',
    name: '您有一份会员权益',
    component: () => import('@/pages/phonebill/pink')
  },
  {
    path: '/redbox',
    name: '恭喜您获得大额话费优惠券',
    component: () => import('@/pages/phonebill/redbox'),
    meta: {
      file_name:'redbox'
    }
  },
  {
    path: '/sale/tarot_activity',
    name: '塔罗测试',
    component: () => import('@/pages/phonebill/tarot_activity'),
    meta: {
      file_name:'tarot_activity'
    }
  },

]
/* 短视频 */
const shortvideoRouter = [//短视频
  {
    path: '/wap/video',
    name: '看视频 抽手机',
    component: () => import('@/pages/shortvideo/video'),
    meta: {
      file_name:'videoPage',
      keepAlive:false
    }
  },
  {
    path: '/wap/video/success',
    name: '支付成功',
    component: () => import('@/pages/shortvideo/success'),
    meta: {
      file_name:'videoSuccess',
      keepAlive:false
    }
  },
  {
    path: '/wap/video/selfRefund',
    name: '自助退款',
    component: () => import('@/pages/shortvideo/self_refund')
  },
  {
    path: '/duanju/pay',
    name: '看剧福利',
    component: () => import('@/pages/shortvideo/duanju/index'),
    meta: {
      keepAlive:false
    }
  },
  {
    path: '/duanju/automaticRefund',
    name: '自助退款',
    component: () => import('@/pages/shortvideo/duanju/refund')
  },
  {
    path: '/duanju/success',
    name: '支付确认',
    component: () => import('@/pages/shortvideo/duanju/success')
  },
  {
    path: '/video/pay',
    name: '看剧福利',
    component: () => import('@/pages/shortvideo/video1/index'),
    meta: {
      keepAlive:false
    }
  },
  {
    path: '/video/automaticRefund',
    name: '自助退款',
    component: () => import('@/pages/shortvideo/video1/refund')
  },
  {
    path: '/video/success',
    name: '支付确认',
    component: () => import('@/pages/shortvideo/video1/success')
  },
]

const renderRouter = [
  {
    path: '/render',
    name: '恭喜你！抽手机福利已锁定',
    component: () => import('@/pages/render/index')
  },
  {
    path: '/render/pay_success',
    name: '支付成功',
    component: () => import('@/pages/render/pay_success')
  },
  {
    path: '/render/success',
    name: '下单成功',
    component: () => import('@/pages/render/success')
  },
]


/* 盒子 */
const boxRouter = [
  {
    path: '/lottery',
    name: '幸福大转盘',
    component: () => import('@/pages/lottery/index'),
    meta: {
      keepAlive:false
    }
  },
  /* taw */
  {
    path: '/taw',
    name: '心动盒子',
    component: () => import('@/pages/box/taw'),
    meta: {
      keepAlive:false
    }
  },
  /* bxm */
  {
    path: '/',
    name: '心动盒子',
    component: () => import('@/pages/box/index4'),
    meta: {
      keepAlive:false
    }
  },
  {
    path: '/index1',
    name: '心动盒子',
    component: () => import('@/pages/box/index1'),
    meta: {
      keepAlive:false
    }
  },
  {
    path: '/index2',
    name: '心动盒子',
    component: () => import('@/pages/box/index2'),
    meta: {
      keepAlive:false
    }
  },
  {
    path: '/index3',
    name: '心动盒子',
    component: () => import('@/pages/box/index3'),
    meta: {
      keepAlive:false
    }
  },
  {
    path: '/index4',
    name: '心动盒子',
    component: () => import('@/pages/box/index4'),
    meta: {
      keepAlive:false
    }
  }
]

/* 贷款 */
const loanRouter = [
  {
    path: '/loan',
    name: '审核',
    component: () => import('@/pages/loan/index'),
    meta: {
      keepAlive:false
    }
  }
]
const routes = [
  {
    path: '/service',
    name: '客服帮助',
    component: () => import('@/pages/common/service'),
    meta: {
      file_name:'service'
    }
  },
  ...phonebillRouter,
  ...shortvideoRouter,
  ...renderRouter,
  ...boxRouter,
  ...loanRouter
]

const router = new VueRouter({
  routes,
  mode:'history',
  // base:'/taw'
  // base:'/bxm'//享开盒子
  // base:'/lottery'//大转盘
  // base:'/box'//享开盒子
  base:process.env.VUE_APP_BASE
})
router.beforeEach( (to,from,next) => {
  if(to.name){
    document.title = to.name
  }
  next()
})
export default router
