<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"/>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"/>
  </div>
</template>
<script>
  import {isWeixin} from '@/utils/utils'
  export default {
    components: {},
    data () {
      return {
        isMobile:true,
        isWeixin:true,
      }
    },
    computed:{

    },
    watch:{

    },
    mounted(){

    },
    created(){
      this.isWeixin = isWeixin()?1:0
    },
    methods: {


    }
  }
</script>
<style lang="less">
  @import "@/assets/css/base.less";
  @import "@/assets/css/global.less";
</style>
